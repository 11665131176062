<template>
  <div>
    <a-table :columns="columns" :dataSource="list" :pagination="paginate" @change="$emit('handleTableChange', $event)" :row-key="(record, index) => record.id + index">
      <span
        slot="id"
        slot-scope="text"
      >{{text}}</span>
      <span slot="name" slot-scope="record"> {{ record.ru.name}}</span>
      <span slot="date" slot-scope="record"> {{ record.active ? 'Активная' : 'Не активная' }}</span>
      <span slot="action" slot-scope="record">
              <a href="javascript: void(0);" class="btn btn-sm btn-light mr-2" @click="$refs.editModal.open(record)">
                <i class="fe fe-edit mr-2" />
                Изменить
              </a>
        <a-popconfirm
          title="Вы уверенны что хотите удалить?"
          ok-text="Да"
          cancel-text="Нет"
          @confirm="removeItem(record.id)"
        >
                <a href="javascript: void(0);" class="btn btn-sm btn-light">
                  <small>
                    <i class="fe fe-trash mr-2" />
                  </small>
                  Удалить
                </a>
              </a-popconfirm>
            </span>
    </a-table>
    <edit-news-tag-modal ref="editModal" @getList="$emit('getList')" />
  </div>
</template>

<script>

import EditNewsTagModal from '@/components/content/news/EditNewsTagModal'

export default {
  name: 'NewsTagsTable',
  props: {
    list: {
      type: Array,
      default: () => {
        return []
      },
    },
    paginate: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },
  data() {
    return {
      columns: [
        {
          title: 'ID',
          dataIndex: 'id',
          scopedSlots: { customRender: 'id' },
        },
        {
          title: 'Название',
          scopedSlots: { customRender: 'name' },
        },
        {
          title: 'Позиция',
          dataIndex: 'position',
        },
        {
          title: 'Статус',
          scopedSlots: { customRender: 'date' },
        },
        {
          title: 'Действия',
          scopedSlots: { customRender: 'action' },
        },
      ],
    }
  },
  methods: {
    async removeItem(id) {
      try {
        await this.$services.delete(`admin/tags/${id}`)
        this.$emit('getList')
      } catch (e) {
        console.log(e)
      }
    },
  },
  components: { EditNewsTagModal },
}
</script>

<style scoped lang="scss">
</style>
